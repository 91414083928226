
import vue from "vue";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import MemberService from "../services/MemberService";
import { OfferedTo } from "../Models/OfferedTo";
import { LotSale } from "../Models/LotSale";
import { SalesInvoice } from "../Models/SalesInvoice";
import { SalesInvoiceLine } from "../Models/SalesInvoiceLine";
import dtFormatter from "../lib/DateTimeFormatter";
import LotService from "../services/lotService";
import FileService from "../services/FileService";

@Component({
  components: {
    SalesInvoiceLines: () => import("./SalesInvoiceLines.vue"),
  },
})
export default class SalesInvoiceCard extends Vue {
  @Prop() private offeringDetails!: OfferedTo;
  @Prop() private selectedLot!: LotSale;

  private invoice: SalesInvoice = {
    Id: "",
    InvoiceNumber: "",
    InvoiceDate: new Date(),
    DueDate: new Date(),
    Name: "",
    Address: "",
    Zipcode: "",
    City: "",
    Email: "",
    InvoiceLines: [],
    InsuranceAmount: 0,
    InsurancePremium: 0,
    TotalAmount: 0,
    HouseNumber: "0",
  };

  private dtFormatter = new dtFormatter();

  @Watch("selectedLot", { deep: true })
  async onPropertyChanged(oldValue: LotSale, newValue: LotSale): Promise<void> {
    var memberService = new MemberService();
    console.log("selected lot changed");
    var invoice = await memberService.GetSalesInvoice(newValue.Id);
    this.invoice = invoice;
  }

  formatAmount(value: number): string {
    const formatter = new Intl.NumberFormat("nl-NL", {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 2,
    });
    return formatter.format(value);
  }

  async SendInvoice(): Promise<void> {
    let memberService = new MemberService();
    await memberService.SendInvoice(this.invoice.Id);
  }

  downloadInvoice(): void {
    window.open(
      "https://localhost:5001/api/file/Lid/" +
        this.offeringDetails.Member?.Id +
        "/" +
        this.invoice.InvoiceNumber +
        ".pdf",
      "_blank"
    );
  }

  async mounted(): Promise<void> {
    var memberService = new MemberService();

    var invoice = await memberService.GetSalesInvoice(this.offeringDetails.Id);
    this.invoice = invoice;
  }
}
